import React from "react";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

import './Form.css';




export function Form() {
    
    const defaultValues = {
        email : "",
    };

    const [loading, setLoading] = React.useState(false);
    const [formValues, setFormValues] = React.useState(defaultValues);
    
    
    //snackbar
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [open, setOpen] = React.useState(false);
    const [openFail, setOpenFail] = React.useState(false);
    const [openWarning, setOpenWarning] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen(false);
        setOpenFail(false);
        setOpenWarning(false);
    };



    function handleInputChange(e) {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    
    //button clicked
    function handleClick(e) {
        console.log(formValues);
        setLoading(true);


        if (Object.values(formValues).includes(""))
        {
            setOpenWarning(true);
            setLoading(false);
        }
        else
        {
            //TODO change lambda endpoint to validation lambda
            axios.post(`https://borzr6rcixxnqrl4wb7r2ofkaq0waumt.lambda-url.us-east-1.on.aws/`, formValues, {
                "Content-Type"      : "application/json",
            })
            .then((data) => {
                console.log(data); // JSON data parsed by `data.json()` call
                setLoading(false);


                setOpen(true);
                
                
                
            })
            .catch((error) => {

                console.error(error);
                setLoading(false);
                setOpenFail(true);

            });
        }



    }
    

    return (
        <div>
            <form  className="mainForm">
                <p className="emailLabel">Please enter your email below:</p>
                <TextField 
                    id="email" 
                    label="Email" 
                    variant="outlined"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange} 
                    required />
                
                <br />

                <LoadingButton
                    onClick={handleClick}
                    loading={loading}
                    variant="contained"
                    id="submitButton"
                >
                    Submit
                </LoadingButton>
            </form>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} >
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Success! Email is sending...
            </Alert>
            </Snackbar>

            <Snackbar open={openFail} autoHideDuration={6000} onClose={handleClose} >
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                Error: Please contact support@altr.com for help
            </Alert>
            </Snackbar>

            <Snackbar open={openWarning} autoHideDuration={6000} onClose={handleClose} >
            <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                Please fill out all fields
            </Alert>
            </Snackbar>


        </div>
    )
}