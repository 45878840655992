import React from "react";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {Form} from "./Form.js";
import './FormModal.css';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: "0px 0px 0px 1px rgba(66, 51, 230, 1)",
    borderRadius: "10px",
    p: 4,
  };

export function FormModal()
{


    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button
            id="nextButton"
            variant="contained"
            onClick={handleOpen} >
                Next
            </Button>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Form />
                </Box>
            </Modal>
        </div>
    )
}