import './App.css';
import {FormModal} from "./FormModal.js"
import {default as ExpandCard} from "./ExpandCard.js"
// import {CopyBlock, dracula } from "react-code-blocks";
import {default as sample} from "./codeText.js";
import SyntaxHighlighter from 'react-syntax-highlighter';
import Button from '@mui/material/Button';

function App() {
  const BlockOne = () => {
    return <>
      <Button
        id="copyOne"
        variant="outlined"
        onClick={() => {navigator.clipboard.writeText(sample["snippetOne"])}} >
            Copy
        </Button>
      <SyntaxHighlighter langauge="sql">
        {sample["snippetOne"]}
      </SyntaxHighlighter>
      
    </>
    
  };

  const BlockTwo = () => {
    return <>
      <Button
        id="copyThree"
        variant="outlined"
        onClick={() => {navigator.clipboard.writeText(sample["snippetTwo"])}} >
            Copy
        </Button>
      <SyntaxHighlighter langauge="sql">
        {sample["snippetTwo"]}
      </SyntaxHighlighter>
      
    </>
  };

  const BlockThree = () => {
    return <>
      <Button
        id="copyThree"
        variant="outlined"
        onClick={() => {navigator.clipboard.writeText(sample["snippetThree"])}} >
            Copy
        </Button>
      <SyntaxHighlighter langauge="sql">
        {sample["snippetThree"]}
      </SyntaxHighlighter>
      
    </>
  };


  return (
    <div className="App">
      <div className="altrImg">
        <a href="http://altr.com" rel="noreferrer" target="_blank"><img src="https://assets.website-files.com/625877bdadf57ec93447d6b4/6258e09090a5ef0686d5c53a_altr-logo.svg" alt="an ALTR logo"
          height="50" width="120"></img></a>
      </div>
      <header className="App-header">
        <div className="instructions">

          <h1>ALTR + BigID Integration</h1>

          <h5>Run the following commands in Snowflake to create and permission an ALTR service user</h5>
          <div class="stepDiv">
            <p className="snippetDesc">1. Create your service user by copying the CREATE USER statement below into your Snowflake Worksheet.</p>
          
            <div className="snippet">

              <ExpandCard snippet={BlockOne} />
            </div>



          </div>
          <div class="stepDiv">
            <p className="snippetDesc">2. Configure Service User with SETUP_ALTR_SERVICE_ACCOUNT for service account configuration. To add this procedure to your Snowflake database, copy and paste the below CREATE OR REPLACE PROCEDURE statement into the Worksheet and press the "Run" button.</p>
            
            <div className="snippet">
              <ExpandCard snippet={BlockTwo} />
            </div>

          </div>
          <div class="stepDiv">
            <p className="snippetDesc">3. Copy and paste the below CALL statement into your Snowflake Worksheet and press the “Run” button to invoke the procedure. This may take multiple minutes to execute, depending on the complexity of your Snowflake configuration.</p>
            <div className="snippet">
              <ExpandCard snippet={BlockThree} />
            </div>
          </div>
        </div>

      </header>
        <FormModal />
    </div>
  );
}


export default App;
