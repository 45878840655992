import './App.css';
import { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function ExpandCard({snippet: CopyBlock}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card sx={{ minWidth: 300, border: "1px solid rgba(211,211,211,0.6)", fontSize: "85%" }}>
        <CardHeader
          title="Code Snippet"
          action={
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        >
        </CardHeader>
        <div style={{ backgroundColor: 'rgba(211,211,211,0.4)'}}>
        <Collapse 
          in={open} 
          timeout="auto"
          collapsedSize="8vh"
        >
          <CardContent>
            <CopyBlock />
          </CardContent>
            
        </Collapse>
        </div>
      </Card>
    </>
  );
}